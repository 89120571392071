
import TransferOwnerDialog from "@/components/shares/TransferOwnerDialog"
import VisibilityDialog from "@/components/shares/VisibilityDialog"
import CreateShareDialog from "@/components/shares/CreateShareDialog"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import Vue from "vue"
import { SharePermission, ShareType } from "@evercam/shared/types/shares"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import { mapStores } from "pinia"
import { useShareStore } from "@/stores/shares"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import { RouteParams } from "@evercam/shared/types/routeParams"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default Vue.extend({
  name: "Share",
  meta: {
    pageId: AnalyticsEventPageId.sharing,
  },
  components: {
    CopyToClipboardBtn,
    TransferOwnerDialog,
    VisibilityDialog,
    CreateShareDialog,
    LogoDisplayer,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, app }) {
    const cameraStore = useCameraStore()

    if (!app.$permissions.user.can.access.sharing()) {
      return redirect(cameraStore.cameraRoute)
    }
    const canViewShares = cameraStore.selectedCamera?.rights
      ?.split(",")
      .find((o) => ["share", "edit", "delete"].includes(o))

    if (!canViewShares) {
      return redirect(cameraStore.cameraRoute)
    }

    return {
      userEmail: useAccountStore().email,
    }
  },
  data() {
    return {
      headers: [
        {
          value: "fullname",
          text: this.$t("content.shares.headers.shared_with"),
          sortable: true,
          width: "33%",
        },
        {
          value: "sharerName",
          text: this.$t("content.shares.headers.shared_by"),
          sortable: true,
          width: "20%",
        },
        {
          value: "rights",
          text: this.$t("content.shares.headers.rights"),
          sortable: false,
        },
        {
          value: "lastSeenAt",
          text: this.$t("content.shares.headers.last_seen"),
          sortable: true,
          width: "10%",
        },
        {
          value: "action",
          text: this.$t("content.shares.headers.action"),
          sortable: false,
          width: "10%",
        },
      ],
      shareTypes: ShareType,
      userEmail: null,
      sortBy: "lastSeenAt",
      sortDesc: true,
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.sharing"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useShareStore, useCameraStore),
    routeCameraExid() {
      const camelizedKeys = camelizeKeys(this.$route.params) as RouteParams

      return camelizedKeys?.cameraExid
    },
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
        },
      ]
    },
    cameraDiscoverability() {
      if (!this.cameraStore.isCameraPublic) {
        return {
          icon: "fa-solid fa-lock",
          description:
            this.$t("content.shares.visibility_options.private.label") +
            ", " +
            this.$t("content.shares.visibility_options.this_camera_is"),
        }
      }

      if (!this.cameraStore.selectedCamera?.discoverable) {
        return {
          icon: "fas fa-link",
          description:
            this.$t(
              "content.shares.visibility_options.public_undiscoverable.label"
            ) +
            ", " +
            this.$t("content.shares.visibility_options.this_camera_is"),
        }
      }

      return {
        icon: "fas fa-globe",
        description:
          this.$t(
            "content.shares.visibility_options.public_discoverable.label"
          ) +
          ", " +
          this.$t("content.shares.visibility_options.this_camera_is"),
      }
    },
    cameraStatusText() {
      if (!this.cameraStore.isCameraPublic) {
        return "Private"
      }

      if (!this.cameraStore.selectedCamera?.discoverable) {
        return "Public, Not Discoverable"
      }

      return "Public"
    },
    cameraUrl() {
      return `${window.location.origin}${this.cameraStore.cameraRoute}`
    },
    isPublicCamera() {
      return this.cameraStore.isCameraPublic
    },
    sort() {
      return { sortBy: this.sortBy, sortDesc: this.sortDesc }
    },
  },
  watch: {
    "cameraStore.cameras"() {
      if (this.cameraStore.cameras?.length) {
        this.cameraStore.selectCamera(this.routeCameraExid)
      }
    },
    "shareStore.addUserDialog"(value) {
      this.$analytics.saveEvent(AnalyticsEvent.sharingToggleAddUserDialog, {
        visible: value,
      })
    },
    sort({ sortBy, sortDesc }) {
      if (sortBy && sortDesc) {
        this.$analytics.saveEvent(AnalyticsEvent.sharingSortBy, {
          sortBy,
          sortDesc,
        })
      }
    },
  },
  async created() {
    await this.shareStore.fetchShareRequests(this.routeCameraExid)
    await this.shareStore.fetchShares(this.routeCameraExid)
    this.shareStore.updateShareObject()
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  methods: {
    getSelectedCameraPublicStatus() {
      return this.cameraStore.isCameraPublic ? "" : this.$t("actions.not")
    },
    getSelectedCameraDiscoverableStatus() {
      return this.cameraStore.selectedCamera?.discoverable
        ? ""
        : this.$t("actions.not")
    },
    fromNow(date) {
      return date ? this.$moment(date).fromNow() : "-"
    },
    formatDate(date) {
      return date && this.$moment(date).format("LLLL")
    },
    async openDeleteDialog() {
      this.$analytics.saveEvent(AnalyticsEvent.sharingToggleDeleteUserDialog, {
        visible: true,
      })
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_sharer"),
          message: this.$t("content.shares.delete_share"),
        })
      ) {
        this.shareStore.deleteShareOrShareRequest(this.routeCameraExid)
      }
      this.$analytics.saveEvent(AnalyticsEvent.sharingToggleDeleteUserDialog, {
        visible: false,
      })
    },
    isRestricted(shareeEmail) {
      return this.$permissions.user.is.restricted(shareeEmail)
    },
    copyCameraUrl() {
      this.$analytics.saveEvent(AnalyticsEvent.sharingCopyLink)
    },
  },
})
